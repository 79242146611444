import {type FC} from "react";
import logo from '../../assets/selfstock-capital-meilleures-enseigne-web.svg';
import Image from 'next/image';

type Props = {
  className?: string
}

export const LogoCapitalBestCompany:FC<Props> = function ({className}) {
  return <Image src={logo} className={className} alt="Logo Palmarès 2022 France Capital meilleure enseignes dans la catégorie Qualité de service" />
};
 