"use client";

import type {FC, ReactNode} from "react";
import {Disclosure} from "@headlessui/react";
import {CaretDown} from "@phosphor-icons/react/dist/ssr/CaretDown";

export type FaqItemProps = {
  label: string,
  description: string,
  descriptionComponent?: ReactNode,
  addToSnippet?: boolean,
}

export const FaqItem:FC<FaqItemProps> = function ({label, description, descriptionComponent}) {
  return <Disclosure>
    {({open}) => (
      <>
        <Disclosure.Button className={`font-bold ${open ? 'text-brand' : ''} flex w-full flex-row items-center justify-between py-4 text-left text-xl`}>
          <div>
            {label}
          </div>
          <div>
            <CaretDown size={20} weight="bold" className={`ml-4 transition-transform ${open ? 'rotate-180' : 'rotate-0'}`} />
          </div>
        </Disclosure.Button>
        <Disclosure.Panel className="py-4">
          {descriptionComponent ?? <p>{description}</p>}
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
};