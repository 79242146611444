"use client";

import React, { type FC, useCallback, useEffect, useState } from 'react';
import { BrandFourStepsItem } from './brand-four-steps-item';
import useEmblaCarousel, { type UseEmblaCarouselType } from 'embla-carousel-react';


export type BrandFourStepsSliderProps = {
  centersCount: number;
  openingTime?: string;
  background?: 'white' | 'grey';
};

type EmblaCarouselType = UseEmblaCarouselType['1'];

const BrandFourStepsSlider: FC<BrandFourStepsSliderProps> = function ({
  centersCount,
  openingTime,
  background,
}) {
  // Keep tracks of the current slide
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  // Main slider
  const [mainSliderRef, mainSlider] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    breakpoints: {
      '(min-width: 1280px)': {
        active: false,
      },
    },
  });

  const onInit = useCallback((slider: EmblaCarouselType ) => {
    if (!slider) return;
    setScrollSnaps(slider.scrollSnapList());
  }, []);
  
  const onSelect = useCallback((slider: EmblaCarouselType) => {
    if (!slider) return;
    setSelectedIndex(slider.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!mainSlider) return;

    onInit(mainSlider);
    onSelect(mainSlider);

    mainSlider.on('reInit', onInit);
    mainSlider.on('reInit', onSelect);
    mainSlider.on('select', onSelect);
  }, [mainSlider, onInit, onSelect]);


  return (
    <>
      <div className={'overflow-hidden'}>
        <div ref={mainSliderRef}>
          <div
            className="!lg:grid-cols-4 !grid gap-4"
            style={{ gridTemplateColumns: 'repeat(4, minmax(290px, 80vw))' }}>
            {/* 1. Trouvez votre centre de stockage */}
            <BrandFourStepsItem
              number={1}
              background={background}
              title={'Trouvez le centre<br />proche de chez vous'}>
              <p>
                Avec {centersCount} centres en France, vous trouverez le votre.
              </p>
            </BrandFourStepsItem>

            {/* 2. Choisissez le box qui convient */}
            <BrandFourStepsItem
              number={2}
              background={background}
              title={'Choisissez<br />votre box'}>
              <p>
                Consultez nos disponibilités en temps réel. Et louez votre box
                pour le jour même ou plus tard !
              </p>
            </BrandFourStepsItem>

            {/* 3. Payez par carte bancaire */}
            <BrandFourStepsItem
              number={3}
              background={background}
              title={'Payez le<br />premier mois'}>
              <p>
                Pour valider la réservation de votre box et générer votre
                contrat de location.
              </p>
            </BrandFourStepsItem>

            {/* 4. Recevez vos codes d'accès */}
            <BrandFourStepsItem
              number={4}
              title={'Récupérez<br />votre clé'}
              background={background}>
              <p>
                À la date de début de location, prenez possession de votre box
                {' ' + (openingTime ?? '24h/24')}, 7j/7 en totale autonomie !
                {!openingTime && '*'}
              </p>
            </BrandFourStepsItem>
          </div>

          {/* Pagination */}
          <div
            className={`mt-6 flex items-center justify-center gap-2 ${
              scrollSnaps.length < 2 ? 'invisible' : ''
            }`}>
            {scrollSnaps.map((_, index) => (
              <div
                key={index}
                className={`size-[8px] rounded-full ${
                  index === selectedIndex ? 'bg-brand' : 'bg-brand-black'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
      {!openingTime && (
        <p className="text-right text-gray-400">
          *Voir les horaires spécifiques sur les pages centres
        </p>
      )}
    </>
  );
};

BrandFourStepsSlider.displayName = 'BrandFourStepsSlider';
export { BrandFourStepsSlider };
