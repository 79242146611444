"use client";

import { StoreLocatorUtils } from "@/utils/store-locator-utils";
import  { type StoreLocatorCenter, type StoreLocatorLocation, StoreLocatorProvider, type StoreLocatorQuery, useStoreLocator } from "@/utils/stores/use-store-locator";
import { type FC, useEffect, useState } from "react";
import { StoreLocatorSearchForm } from "./form/store-locator-search-form-v2";
import { StoreLocatorSearchFormFrame } from "./form/store-locator-search-form-frame";
import { StoreLocatorEventListenerProvider } from "./store-locator-event-listener-provider";
import { StoreLocatorGoogleMap } from "./store-locator-google-map";

type Props = {
  showInput?: boolean,
  lazyLoad?: boolean,
  showCardsFooter?: boolean;
}

const StoreLocatorMap: FC<Props> = function ({showInput = true, lazyLoad, showCardsFooter}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [distance, displayMode, query, setIsLoading, setQuery, setLocation, setCenters] = useStoreLocator((state) => [
    state.query.distance,
    state.displayMode,
    state.query,
    state.setIsLoading,
    state.setQuery, 
    state.setLocation,
    state.setCenters,
  ]);

  const handleQueryChange = async (
    values: StoreLocatorQuery
  ) => {
    setIsLoading(true);

    const response = await StoreLocatorUtils.fetchStoreLocatorSearchResults(
      'france',
      values
    );
    setQuery(response.query);
    setLocation(response.location);
    setCenters(response.centers);

    setIsLoading(false);
  };

  return <>
    {/* Search form */}
    {showInput && <StoreLocatorSearchFormFrame className="absolute inset-x-4 -top-4 z-10 mx-auto max-w-lg lg:ml-0" intent="white">
      <StoreLocatorSearchForm onChange={handleQueryChange} />
    </StoreLocatorSearchFormFrame>}

    {/* Map */}
    <StoreLocatorGoogleMap lazyLoad={lazyLoad} showCardsFooter={showCardsFooter} />
  </>
};


const StoreLocatorMapProvidersWrapper : FC<Props> = function(props) {
  const [data, setData] = useState<{location : StoreLocatorLocation, centers: StoreLocatorCenter[], query: StoreLocatorQuery}>();

  useEffect(() => {
    StoreLocatorUtils.fetchStoreLocatorSearchResults('france').then(({query, location, centers}) => {
      setData({
        query,
        location, 
        centers
      });
    });
  }, []);

  return <div className="relative h-full">
    {data && <StoreLocatorProvider centers={data.centers} location={data.location} query={data.query}>
      <StoreLocatorEventListenerProvider>
        <StoreLocatorMap {...props} />
      </StoreLocatorEventListenerProvider>
    </StoreLocatorProvider>}
  </div>
}
StoreLocatorMapProvidersWrapper.displayName = 'StoreLocatorMapProvidersWrapper';
StoreLocatorMap.displayName = 'StoreLocatorMap';
export { StoreLocatorMapProvidersWrapper as StoreLocatorMap };