import {type FC, memo, type ReactNode} from "react";


type Props = {
  number: number,
  title: string,
  children: ReactNode,
  active?: boolean,
  background?: 'grey' | 'white'
}

const BrandFourStepsItem: FC<Props> = memo(function ({number, title, children, active, background= 'grey'}) {
  return <div className={`${background === 'grey' ? 'bg-light-grey' : 'bg-white'}  flex h-full flex-col rounded-xl p-6 ${active && 'border-4 border-brand'}`}>
    <h3 className={"mb-4 flex flex-row items-center"}>
      <span className="mr-4 text-[52px] font-semibold text-brand">{number}</span>
      <span className="font-bold leading-5" dangerouslySetInnerHTML={{__html: title}} />
    </h3>

    {children}
  </div>
});

BrandFourStepsItem.displayName = 'BrandFourStepsItem';
export { BrandFourStepsItem };
