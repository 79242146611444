"use client";

import { Popover } from '@headlessui/react';
import Link from 'next/link';
import type { FC, ReactNode } from 'react';
import { GhostButton } from '../button/ghost-button';

interface Props {
  children: ReactNode | ReactNode[];
  icon?: ReactNode;
  size?: 'md' | 'lg';
  inline?: boolean;
};

interface PropsDesc extends Props { 
  description: string;
};

interface PropsHref extends Props {
  href: string;
};

export const ReassuranceItem: FC<Props | PropsDesc | PropsHref> = function ({
  icon,
  children,
  size = 'md',
  inline,
  ...props
}) {
  const c: ReactNode[] = Array.isArray(children) ? children : [children];

  const output = (
    <GhostButton className={`flex ${
      inline ? 'flex-row justify-center space-x-2' : 'flex-col'
    } flex-1 items-center text-center`} fullWidth>
      {/* Icon div */}
      <div
        className={`${
          inline !== true && 'mb-1'
        } flex items-center justify-center ${
          size === 'lg' ? 'size-20' : 'size-14'
        }`}>
        {icon}
      </div>

      {/* Label div */}
      <div className={inline ? 'text-left' : 'text-center'}>
        <div
          className={`font-semibold ${
            size === 'lg' ? 'text-xl lg:text-2xl' : 'text-lg md:text-sm'
          }`}>
          {c[0]}
        </div>

        {/* Description text div */}
        {c[1] && <div className="mt-4">{c[1]}</div>}
      </div>
    </GhostButton>
  );

  const wrapperClassName = `${
    size === 'lg' ? 'space-y-4' : ''
  } relative flex flex-col items-center flex-1`;

  if ("href" in props && props.href)
    return (
      <Link href={props.href} className={wrapperClassName}>
        {output}
      </Link>
    );

  if ("description" in props && props.description)
    return (
      <Popover className={wrapperClassName}>
        <Popover.Button as="div">
          {output}
        </Popover.Button>
        <Popover.Panel className="absolute left-0 top-full z-10 -mt-2 w-screen max-w-xs">
          <div className="rounded-lg bg-brand-black p-4 text-xs text-white shadow-lg">
            {props.description}
          </div>
        </Popover.Panel>
      </Popover>
    );

  return <div className={wrapperClassName}>{output}</div>;
};