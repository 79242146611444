"use client"

import type { FC } from "react";
import { LogoCapitalBestCompany } from "./logo-capital-best-company";
import { Popover } from '@headlessui/react';
import { Question } from "@phosphor-icons/react/dist/ssr/Question";

type Props = {
  className?:string,
  textClass: string,
  centerCount: number,
  sinceYears: number
}

export const BrandLegitimityText: FC<Props> = function({className = '', textClass, centerCount, sinceYears}) {
  return <Popover className={className + 'relative text-pretty'}>
  <span className={textClass}>{centerCount}</span> agences partout en France
  - Élue{" "}
  <Popover.Button as="span" className={`${textClass} inline items-center gap-2 hover:underline cursor-pointer`}>
      parmi les meilleures enseignes 2022 <Question weight="bold" className="inline-block align-text-top" size={"1em"} />
    </Popover.Button>{" "}
  - Déjà <span className={textClass}>{sinceYears} années</span> à votre
  service

  <Popover.Panel className={"absolute z-10 bg-white shadow-xl rounded-lg bottom-full left-1/2 -translate-x-1/2 mt-8 max-w-sm p-4"}>
      <LogoCapitalBestCompany />
    </Popover.Panel>
</Popover>;
};