"use client";

import { useRouter } from "next/navigation";
import { StoreLocatorUtils } from "@/utils/store-locator-utils";
import type { StoreLocatorQuery } from "@/utils/stores/use-store-locator";
import { useState, type FC} from "react";
import { StoreLocatorSearchForm } from "./store-locator-search-form-v2";

export const StoreLocatorStandaloneForm: FC = function() {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  const handleQueryChange = async (values: StoreLocatorQuery) => {
    setLoading(true);
    router.push(StoreLocatorUtils.buildUrl('france', values, {scrollToMap: true}));
  };

  return <StoreLocatorSearchForm onChange={handleQueryChange} isLoading={loading} />
};